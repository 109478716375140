import React from "react"
import "./Overview.less"
const Overview = ({
  data,
  bgPic = undefined,
  title = "Market Overview",
  description,
}: {
  data: any
  bgPic?: string
  title?: string
  description?: string
}) => {
  const Item = ({
    img,
    title,
    content,
    icon,
  }: {
    img: string
    title?: string
    content: string
    icon: string
    bgPic?: string
  }) => {
    return (
      <div className="solution-overview-item clearfix block-middle">
        <div className="imgContainer">
          <img src={img} />
        </div>
        <div className={`solution-overview-itemContent textContainer`}>
          <img src={icon} alt="" className="solution-overview-icon" />
          <h3 className="font-36 semi-bold t-color mt-24">{title}</h3>
          <div className="mt-24 font-20" style={{ color: "#616E82" }}>
            {content}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      className="solution-overview full-bg"
      style={bgPic ? { backgroundImage: `url("${bgPic}")` } : {}}
    >
      <h2 className="font-36 semi-bold t-color text-center">{title}</h2>
      {description && <div className="description">{description}</div>}
      <div className="page-middle">
        <div className="solution-overview-content">
          {data.map(({ title, content, img, icon }) => (
            <Item title={title} content={content} img={img} icon={icon} />
          ))}
        </div>
      </div>
    </div>
  )
}
export default Overview
