import React from "react"
import "./CompleteSolution.less"
const defaultData = {
  title: "Complete solutions",
  content: [
    {
      name: "Gaming",
      description:
        "Give your players exceptional digital user experience with ultra-low latency and a flexible solution that scales to meet demand.",
    },
    {
      name: "Media streaming",
      description:
        "Stream audio and video with dynamic acceleration to seamlessly deliver content without interruption.",
    },
    {
      name: "Interactive video",
      description:
        "Reduce lag and increase interaction during virtual doctor visits, online classes, e-meetings, and more.",
    },
    {
      name: "Remote workers",
      description:
        "Accelerate SaaS applications (Office 365, Google Suite, etc.) and intranet access for employees both in the office and working remotely.",
    },
  ],
}
const CompleteSolutions = ({
  title = defaultData.title,
  content = defaultData.content,
  background = "linear-gradient(#4ec3fe, #0ca8fd)",
}: {
  title?: string
  content?: { name; description }[]
  background?: string
}) => {
  const Item = ({ title, desc, img }) => (
    <div className="completeSolution-item">
      <img src={img} alt={title} />
      <h3 className="font-20 semi-bold t-color mt-16">{title}</h3>
      <div className="completeSolution-item-desc">{desc}</div>
    </div>
  )
  const ItemMobile = ({ title, desc, img }) => (
    <div className="completeSolution-item-mobile">
      <img src={img} alt={title} />
      <div>
        <div className="font-20 semi-bold t-color mt-16">{title}</div>
        <div className="completeSolution-item-desc">{desc}</div>
      </div>
    </div>
  )
  return (
    <div className="completeSolution" style={{ background }}>
      <h2
        className="semi-bold font-36 text-center white"
        style={{ color: "#fff" }}
      >
        {title}
      </h2>
      <div className="completeSolution-container mt-56">
        {content.map(({ name, description }, idx) => (
          <Item
            title={name}
            desc={description}
            img={`/solution/china/completeSolution-${idx + 1}.png`}
          />
        ))}
        {content.map(({ name, description }, idx) => (
          <ItemMobile
            title={name}
            desc={description}
            img={`/solution/china/completeSolution-${idx + 1}-m.png`}
          />
        ))}
      </div>
    </div>
  )
}
export default CompleteSolutions
