import React, { useState } from 'react'
import Footer from '../../components/footer'
import { graphql, Link, navigate } from 'gatsby'
import { ProductNav } from '../../components/header/Header'
import '../../styles/solution/india.less'
import SEO from '../../components/SEO'
import { Button, Tab } from '../../components/ui'
import { GetStarted } from '../../pages-en/index'
import Overview from '../../components/block/solution/Overview'
import { Solution, Feedback } from '../products/bare-metal-cloud'
import Drop from '../../components/drop'
import ResourceMap, { CityGroup } from '../../components/map'
import { SolutionTopBanner, ProductBlog } from '../../components/block'
import CompleteSolutions from '../../components/block/solution/CompleteSolution'

import { lang } from '../../utils'
const loadText = require('src/utils').loadText
let static_data
if (
  typeof window !== 'undefined' &&
  window.location.hostname === 'www.zenlayer.in'
) {
  static_data = require('../../data/in/unleash-indias-potential.json')
} else {
  static_data = loadText('unleash-indias-potential')
}
const Number = () => (
  <div className="india-number">
    <div className="india-number-container full-bg">
      <div className="page-middle india-number-content">
        <h2 className="font-36 semi-bold white">
          {static_data.byTheNumber.title}
        </h2>
        <div className="india-number-text font-20 mt-24">
          {static_data.byTheNumber.description}
        </div>
        <div className="mt-32">
          <Button
            width={391}
            style={{ color: '#24B7FF', background: '#fff' }}
            onClick={() => {
              if (lang === 'zh') {
                window.open('/印度互联网分析报告.pdf')
              } else {
                navigate('/by-the-numbers/india')
              }
            }}
          >
            {static_data.byTheNumber.buttonText}
          </Button>
        </div>
      </div>
    </div>
  </div>
)

const Differences = () => (
  <div className="india-difference">
    <div className="india-difference-b1">
      <h2 className="font-36 t-color semi-bold">
        {static_data.differences.b1.title}
      </h2>
      <div
        className="mt-24 font-20"
        dangerouslySetInnerHTML={{
          __html: static_data.differences.b1.description,
        }}
      />
      {/* <div className="mt-56">
        <Button
          width={263}
          onClick={() => {
            navigate("/blog/tag/india/1")
          }}
        >
          {static_data.differences.b1.buttonText}
        </Button>
      </div> */}
    </div>
    <div className="india-difference-fullbg">
      <div className="india-difference-b2b3">
        <div className="india-difference-b2">
          <div className="page-middle">
            <div className="india-difference-b2-content">
              <h3
                className="font-36 t-color semi-bold"
                style={{ maxWidth: '478px' }}
              >
                {static_data.differences.b2.title}
              </h3>
              <div className="mt-24 font-20" style={{ maxWidth: '548px' }}>
                {static_data.differences.b2.description}
              </div>
              <img
                src="/solution/india/b2-pic.png"
                alt={static_data.differences.b2.title}
              />
            </div>
          </div>
        </div>
        <div className="india-difference-b3 mobile-padding">
          <h2 className="font-36 t-color semi-bold">
            {static_data.differences.b3.title}
          </h2>
          <span className="mt-24 font-20">
            {static_data.differences.b3.description}
          </span>
        </div>
      </div>
    </div>
    <div className="india-difference-b4">
      <div className="page-middle">
        <Drop color="#fff" bgColor="#F3F5F9" style={{ top: 0 }} />
        <h2 className="font-36 t-color semi-bold mobile-padding">
          {static_data.differences.b4.title}
        </h2>
        <div className="india-difference-b4-desc font-20 mt-24 mobile-padding">
          {static_data.differences.b4.description}
        </div>
        {[
          'STT',
          'Tata',
          'Nxtra',
          'Airtel',
          'Sify',
          // "GPX",
          'Ctrl S',
          'Netmagic',
        ].map((name) => (
          <div className="india-difference-b4-icon">
            <img
              src={`/solution/india/india-partner-${name}.svg`}
              alt={`${name}`}
            />
            <div className="font-20 mt-8">{name}</div>
          </div>
        ))}
        <div className="font-20 mt-56">Internet exchanges</div>
        {/* <div className="iconGroup"> */}
        {['NIXI', 'DE-CIX', 'AMS-iX', 'Extreme IX'].map((name) => (
          <div className="india-difference-b4-ix mt-24">
            <img
              src={`/solution/india/india-exchange-${name}.svg`}
              alt={name}
            />
            <div className="font-20 mt-8">{name}</div>
          </div>
        ))}
        {/* </div> */}
      </div>
    </div>
    <div className="india-difference-b5">
      <div className="india-difference-b5-container">
        <div className="page-middle">
          <h2 className="font-36 semi-bold india-difference-b5-title white">
            {static_data.differences.b5.title}
          </h2>
          <div className="font-20 mt-24 india-difference-b5-text">
            {static_data.differences.b5.description}
          </div>
        </div>
      </div>
    </div>
  </div>
)
export const Map = ({ cities, title, description, initPos }) => {
  const [highlight, setHighlight] = useState([])

  return (
    <div className="india-map">
      <h2 className="font-36 semi-bold t-color mobile-padding">{title}</h2>
      <div
        className="font-20 mt-24 block-middle mobile-padding"
        style={{ maxWidth: '981px' }}
      >
        {description}
      </div>
      <div>
        <CityGroup
          data={cities}
          onClick={(coord) => {
            setHighlight(coord)
          }}
        />
        <ResourceMap highlight={highlight} initPos={initPos} />
      </div>
    </div>
  )
}
export default function Home({ data }) {
  const { allStrapiPosts, strapiTestimonials } = data
  return (
    <div className="india">
      <SEO {...static_data.seo} featuredImage="/featuredImage/india.jpg" />
      <SolutionTopBanner
        backgroundAnimated={true}
        {...static_data.topBanner}
        bgColor="linear-gradient(180deg,rgba(242,253,255,1) 0%,rgba(224,249,255,1) 100%)"
        bgPic="/solution/india/topBanner-bg.svg"
        pic={{
          src: '/solution/india/topBanner-pic.png',
          style: { right: 0, bottom: '61px', position: 'absolute' },
        }}
        picM={{
          src: '/solution/india/topBanner-pic-m.png',
          style: { right: 0, bottom: '61px', position: 'absolute' },
        }}
        source="india"
        bannerPic={{
          src: '/solution/india/topBanner-bg-m.svg',
          style: {
            backgroundPosition: 'top center',
          },
        }}
      />
      <ProductNav
        name={static_data.topBanner.title}
        menus={static_data.navigation}
        minWidth={1100}
      />
      <a id="marketOverview" />
      <Overview
        title={static_data.overview.title}
        bgPic="/solution/india/overview-bg.svg"
        data={static_data.overview.content.map((i, idx) => ({
          ...i,
          img: `/solution/india/solution-${idx + 1}.png`,
          icon: `/solution/india/overview-icon-${idx + 1}.svg`,
        }))}
      />
      <Number />
      <a id="difference" />
      <Differences />
      {strapiTestimonials && (
        <div
          className="feedbackContainer"
          style={{
            padding: '20px 0 104px 0',
            background:
              'linear-gradient(90deg,rgba(225,245,255,1) 0%,rgba(179,229,255,1) 100%)',
          }}
        >
          <Feedback {...strapiTestimonials} />
        </div>
      )}

      <Map
        initPos={{ zoom: 4, latlng: [21, 76] }}
        cities={static_data.cities}
        title={static_data.map.title}
        description={static_data.map.description}
      />
      <CompleteSolutions />
      <a id="solution" />
      <Solution {...static_data.solution} />
      <a id="learnMore" />
      <ProductBlog
        title={static_data.blog.title}
        // bgColor="#fff"
        blogs={allStrapiPosts.edges.map(({ node }) => node)}
      />
      <GetStarted source="india" />
      <Footer />
    </div>
  )
}

export const query = graphql`
  {
    allStrapiPosts(filter: { tags: { elemMatch: { name: { eq: "India" } } } }) {
      edges {
        node {
          slug
          wp_date
          content
          title
          categories {
            name
          }
          created_at
          thumbnail {
            localFile {
              publicURL
            }
          }
          author {
            slug
            name
          }
          excerpt
        }
      }
    }
    strapiTestimonials(inPage: { eq: "india" }) {
      customerTitle
      customerName
      content
      companyPreview {
        localFile {
          publicURL
        }
      }
      logo {
        localFile {
          publicURL
        }
      }
      companyName
    }
  }
`
