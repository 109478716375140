import React from "react"
import "./Differences.less"
const Differences = ({
  title,
  description,
  content,
  bgPic = "/solution/china/difference-bg.svg",
}) => (
  <div
    className="solution-difference full-bg"
    style={{ backgroundImage: `url(${bgPic})` }}
  >
    <div className="page-middle">
      <h2 className="font-36 semi-bold t-color block-middle mobile-padding">
        {title}
      </h2>
      {description && (
        <div
          className="font-20 mt-24 block-middle mobile-padding"
          style={{ maxWidth: "1016px" }}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      )}
      <div className="solution-difference-content-bg">
        <div className="solution-difference-content page-middle ">
          {content.map(({ title, description, img, imgM }, i) => (
            <div className={`solution-difference-contentItem mobile-padding`}>
              <div className="left">
                <img src={img} alt={`difference-${i + 1}`} />
              </div>
              <div className="right">
                <h3 className="font-36 semi-bold t-color only-desktop">
                  {title}
                </h3>
                <div
                  className="only-mobile title"
                  style={{
                    backgroundImage: `url(${imgM})`,
                  }}
                >
                  {title}
                </div>
                {typeof description === "string" ? (
                  <div
                    className="font-20 mt-24"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                ) : (
                  description.map(i => <div className="font-20 mt-24">{i}</div>)
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
)
export default Differences
